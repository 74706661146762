import React from 'react'

const h2Style = 'text-gray-900 text-2xl font-semibold mt-8 mb-2'
const pStyle = 'text-gray-900 mb-3'

const PageServicesSection = () => (
  <div>
    <div className='flex flex-col w-full bg-red-700 items-center font-body justify-center px-4 py-8 mx-auto'>
      <h1 className='font-display text-4xl text-white text-center'>Fire Extinguishers Inspections, Recharge, Sales & Repairs</h1>
    </div>

    <div className='px-24 py-12 sm:px-4 md:px-8 sm:py-12'>
      <div className='sm:w-full w-11/12 xl:w-6/12 mx-auto mb-12'>
        <p className={pStyle}>South Bay Fire, Inc. specializes in portable fire extinguisher sales, inspection, recharge & repairs. Whether it is Class A, B, C, D or K, ask our experts which type of fire extinguisher is needed to protect against your specific hazard and we will provide you with all of your options. Once we become your choice fire protection company, we will track the required services needed and notify you at the appropriate times eliminating costly citations and fees.</p>

        <h2 className={h2Style}>5-Year Testing of Class I, II & III Standpipe Systems</h2>

        <p className={pStyle}>At South Bay Fire, Inc., we are committed to protecting lives and property. No matter how small or large the job, our highly trained team does it all. Contact us today so we can help ensure your environment is fire safe.</p>

        <h2 className={h2Style}>Commercial Kitchen Fire Suppression Systems</h2>

        <p className={pStyle}>At South Bay Fire Inc., we plan, design, install and perform required semi-annual service for commercial kitchen fire suppression systems. Commercial kitchens are one of the most risky locations in which fires start. Contact us so you can make sure your fire suppression system is operating reliably.</p>
      </div>
    </div>
  </div>
)

export default PageServicesSection